import * as i0 from '@angular/core';
import { EventEmitter, Directive, HostBinding, Output, Input, NgModule } from '@angular/core';
import { fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
const TRANSITION_TIME = 350;
// eslint-disable-next-line @angular-eslint/component-class-suffix
class MdbCollapseDirective {
  _elementRef;
  _renderer;
  constructor(_elementRef, _renderer) {
    this._elementRef = _elementRef;
    this._renderer = _renderer;
  }
  collapseClass = true;
  collapseShow = new EventEmitter();
  collapseShown = new EventEmitter();
  collapseHide = new EventEmitter();
  collapseHidden = new EventEmitter();
  set collapsed(collapsed) {
    if (collapsed !== this._collapsed) {
      collapsed ? this.hide() : this.show();
      this._collapsed = collapsed;
    }
  }
  get collapsed() {
    return this._collapsed;
  }
  _collapsed = true;
  get host() {
    return this._elementRef.nativeElement;
  }
  _isTransitioning = false;
  show() {
    if (this._isTransitioning || !this.collapsed) {
      return;
    }
    this.collapseShow.emit(this);
    this._renderer.removeClass(this.host, 'collapse');
    this._renderer.addClass(this.host, 'collapsing');
    this._renderer.setStyle(this.host, 'height', '0px');
    this._isTransitioning = true;
    const scrollHeight = this.host.scrollHeight;
    fromEvent(this.host, 'transitionend').pipe(take(1)).subscribe(() => {
      this._isTransitioning = false;
      this.collapsed = false;
      this._renderer.removeClass(this.host, 'collapsing');
      this._renderer.addClass(this.host, 'collapse');
      this._renderer.addClass(this.host, 'show');
      this._renderer.removeStyle(this.host, 'height');
      this.collapseShown.emit(this);
    });
    this._emulateTransitionEnd(this.host, TRANSITION_TIME);
    this._renderer.setStyle(this.host, 'height', `${scrollHeight}px`);
  }
  hide() {
    if (this._isTransitioning || this.collapsed) {
      return;
    }
    this.collapseHide.emit(this);
    const hostHeight = this.host.getBoundingClientRect().height;
    this._renderer.setStyle(this.host, 'height', `${hostHeight}px`);
    this._reflow(this.host);
    this._renderer.addClass(this.host, 'collapsing');
    this._renderer.removeClass(this.host, 'collapse');
    this._renderer.removeClass(this.host, 'show');
    this._isTransitioning = true;
    fromEvent(this.host, 'transitionend').pipe(take(1)).subscribe(() => {
      this._renderer.removeClass(this.host, 'collapsing');
      this._renderer.addClass(this.host, 'collapse');
      this._isTransitioning = false;
      this.collapsed = true;
      this.collapseHidden.emit(this);
    });
    this._renderer.removeStyle(this.host, 'height');
    this._emulateTransitionEnd(this.host, TRANSITION_TIME);
  }
  toggle() {
    if (this._isTransitioning) {
      return;
    }
    this.collapsed = !this.collapsed;
    this.collapsed ? this.hide() : this.show();
  }
  _reflow(element) {
    return element.offsetHeight;
  }
  _emulateTransitionEnd(element, duration) {
    let eventEmitted = false;
    const durationPadding = 5;
    const emulatedDuration = duration + durationPadding;
    fromEvent(element, 'transitionend').pipe(take(1)).subscribe(() => {
      eventEmitted = true;
    });
    setTimeout(() => {
      if (!eventEmitted) {
        element.dispatchEvent(new Event('transitionend'));
      }
    }, emulatedDuration);
  }
  static ɵfac = function MdbCollapseDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MdbCollapseDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: MdbCollapseDirective,
    selectors: [["", "mdbCollapse", ""]],
    hostVars: 2,
    hostBindings: function MdbCollapseDirective_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("collapse", ctx.collapseClass);
      }
    },
    inputs: {
      collapsed: "collapsed"
    },
    outputs: {
      collapseShow: "collapseShow",
      collapseShown: "collapseShown",
      collapseHide: "collapseHide",
      collapseHidden: "collapseHidden"
    },
    exportAs: ["mdbCollapse"]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MdbCollapseDirective, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: '[mdbCollapse]',
      exportAs: 'mdbCollapse'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }], {
    collapseClass: [{
      type: HostBinding,
      args: ['class.collapse']
    }],
    collapseShow: [{
      type: Output
    }],
    collapseShown: [{
      type: Output
    }],
    collapseHide: [{
      type: Output
    }],
    collapseHidden: [{
      type: Output
    }],
    collapsed: [{
      type: Input
    }]
  });
})();
class MdbCollapseModule {
  static ɵfac = function MdbCollapseModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MdbCollapseModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: MdbCollapseModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MdbCollapseModule, [{
    type: NgModule,
    args: [{
      declarations: [MdbCollapseDirective],
      exports: [MdbCollapseDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { MdbCollapseDirective, MdbCollapseModule };
